<template>
  <div class="content">
    <div class="topbox">
      <div class="top clearfix">
        <div class="searchtop clearfix">
          <button class="btnicon">
            <md-icon name="search" size="lg" color="#A5A5A5"></md-icon>
          </button>
          <input
            type="text"
            placeholder="请输产品名称"
            v-model="searchValue"
            v-on:focus="getFocus"
            :autofocus="searchflag"
            class="searchinput"
            ref="searchinput"
          />
          <button class="btnclear" v-show="isclear" @click="clearinput">
            <md-icon name="fail" size="lg" color="#989898"></md-icon>
          </button>
          <!-- v-on:blur="yourmethod"           -->
        </div>
        <div class="searchTxt" @click="yourmethod">搜索</div>
      </div>
      <div class="bottom">
        <div class="left">
          <!-- <input v-model="shortname"
                 readonly
                 @click="changeCompany"
                 type="text"
                 placeholder="保险公司"
                 class="chosegs"
          style="color:#0066cc;width: 90%;" />-->
          <div @click="changeCompany">
            <span
              :class="(shortname=='')?'chosegs':'unchosegs'"
            >{{shortname == ''?'保险公司':shortname}}</span>
            <md-icon name="arrow-down" color="#959595" size="lg"></md-icon>
          </div>
          <md-selector
            v-model="isSelectorShow"
            :data="insuranceCompany"
            :default-value="form.shortname"
            max-height="320px"
            title="请选择保险公司"
            @choose="onSelectorChoose"
          ></md-selector>
        </div>
        <div class="middle">
          <!-- <input v-model="shortAgename"
                 readonly
                 @click="changeAge"
                 type="text"
                 placeholder="投保年龄"
                 class="chosegs"
          style="color:#0066cc;width: 90%;" />-->
          <div @click="changeAge">
            <span
              :class="(shortAgename=='')?'chosegs':'unchosegs'"
            >{{shortAgename == ''?'投保年龄':shortAgename}}</span>
            <md-icon name="arrow-down" color="#959595" size="lg"></md-icon>
          </div>
          <md-selector
            v-model="isSelectorAgeShow"
            :data="insuranceAge"
            :default-value="form.shortAgename"
            max-height="320px"
            title="请选择投保年龄"
            @choose="onSelectorChooseAge"
          ></md-selector>
        </div>
        <div class="right">
          <div @click="changeType">
            <span :class="(typename=='')?'chosegs':'unchosegs'">{{typename == ''?'产品分类':typename}}</span>
            <md-icon name="arrow-down" color="#959595" size="lg"></md-icon>
          </div>
          <md-selector
            v-model="isChoseShow"
            :data="screenlist"
            :default-value="form.typename"
            max-height="320px"
            title="请选择产品分类"
            @choose="onTypeChoose"
          ></md-selector>
        </div>
      </div>
    </div>
    <div class="contentbox">
      <md-scroll-view
        ref="scrollView"
        v-show="!isNull"
        auto-reflow
        :scrolling-x="false"
        @end-reached="loadMore"
      >
        <div class="showItem" v-for="(item, index) in articlelist" :key="index">
          <div class="showLeft">
            <div class="showImg">
              <img :src="ossurl + item.iconhttp" alt />
            </div>
            <div class="showBtn" @click="showModal(item)">
              <div class="sb_btn">
                <span :style="`color: ${colorprimary};`">签单必看</span>
              </div>
              <!-- <div class="sb_btn">
                <span class="sb_tb">投保</span>
              </div>-->
            </div>
          </div>
          <div class="showRight" @click="jump(item)">
            <div class="showTitle">
              <span>{{item.prodname}}</span>
            </div>
            <!-- <div class="label">
              <span class="field">新产品</span>
              <span class="fields">智能核保</span>
            </div>-->
            <div class="showPercent">
              <span>{{item.pdesc}}</span>
            </div>
            <div class="showMoney">
              <div class="money1" v-if="item.minprice ">
                <span>￥{{item.minprice}}元</span>
              </div>
              <div class="money2" v-if="item.minprice">
                <span>起</span>
              </div>
            </div>
          </div>
        </div>
        <div class="showUrl"></div>
        <md-scroll-view-more slot="more" :is-finished="loading"></md-scroll-view-more>
      </md-scroll-view>
      <div class="nodata" v-show="isNull">
        <img class="kongimg" src="@/assets/nh/nhwxtynull.jpg" alt />
      </div>
    </div>
  </div>
</template>

<script>
import { DoRecord, selectMJ } from '@/api/abt/customerOperation/common/index'
import {
  sxchoseList,
  choseSupplyList,
  showList,
  choseList,
  selectcpzl
} from '@/api/agent/agent/index'
import nhwx from '@/views/basic/home/component/nhwx'
import { getStorage } from '@/lib/util'
import { thistle } from 'color-name'
import loadMore from '@/mixins/loadmore' // 下拉加载的
import { Toast } from 'mand-mobile'
import initWebSocket from '@/mixins/websock'
import { Dialog } from 'mand-mobile'
export default {
  components: {
    nhwx
  },
  mixins: [initWebSocket, loadMore], // 下拉加载的
  data () {
    return {
      colorprimary: '',
      typecode: '',
      ossurl: '',
      searchValue: '',
      page: 1,
      pageNo: 1,
      size: 10,
      baseid: '', // 保险公司选择
      prodtype: '', // 筛选的
      search: '', // 搜索的
      isSelectorShow: false,
      isChoseShow: false,
      classname: '',
      shortname: '',
      typename: '',
      // company:'',
      insuranceCompany: [],
      screen: '',
      screenlist: [],
      articlelist: [],
      form: {
        shortname: '',
        typename: '',
        shortAgename: ''
      },
      arr: [],
      arrtype: [],
      list: 10,

      isFinished: false,
      isNull: false,
      total: '',
      totalPage: '',
      height: '',
      loading: false,
      isclear: false,

      shortAgename: '',
      isSelectorAgeShow: false,
      insuranceAge: [],
      isShowModal: false,
      ziliao: [],

      user: '',
      websock: null,
      sno: '',
      empno: '',
      rytype: '',
      suserid: '',
      searchflag: false,
      flag: ''
    }
  },
  beforeRouteLeave (to, from, next) {
    to.meta.title = this.classname
    next()
  },
  created () {
    this.colorprimary = getStorage('theme', '')
    // console.log(this.$route.query.searchflag)
    this.searchflag = this.$route.query.searchflag
    // if (this.$route.query.searchflag) this.$nextTick(() => this.$refs.searchinput.focus())
    this.searchValue = this.$route.query.searchdata
    this.typecode = this.$route.query.sclasscode || ''
    this.ossurl = getStorage('ossurl', '')
    this.flag = getStorage('flag', '')

    this.user = getStorage('u_s', {})
    this.sno = this.$route.query.prodcode
    this.empno = this.$route.query.empno
      ? this.$route.query.empno
      : this.user.empno
    this.rytype = this.$route.query.rytype
      ? this.$route.query.rytype
      : this.user.rytype
    this.suserid = this.$route.query.suserid
      ? this.$route.query.suserid
      : this.user.userid

    // this.showList()
    this.choseSupplyList()
    this.choseList()
    this.getAge()
    if (this.typecode != '') {
      this.prodtype = this.typecode
      this.typename = this.$route.query.typename
      this.pageNo = 1
      this.getData()
    } else {
      this.getData() // 下拉加载
    }
    this.height = document.body.clientHeight - 106
  },

  mounted () {
    // if (window.location.pathname == '/productDisplayNhsx') {
    document.querySelector('.contentbox').style.height = `${this.height}px`
    // } else {
    //   document.querySelector('.contentbox').style.height = `${this.height - 30}px`
    // }
    $(document).on('focusout', function () {
      setTimeout(() => {
        // 软键盘收起的事件处理
        window.scroll(0, 0)
      }, 300)
    })
  },
  methods: {
    yourmethod () {
      this.pageNo = 1
      this.getData()
      // console.log("失去焦点",this.searchValue)
    },
    getFocus () {
      this.isclear = true
    },
    clearinput () {
      this.searchValue = ''
      this.isclear = false
      // this.pageNo = 1
      // this.getData()
    },
    jump (val) {
      if (getStorage('u_s', '').rytype == 'W' && getStorage('u_s', '').status == '6') {
        this.$router.push('/diaoauth')
        return false
      } else {
        this.classname = val.classname
        // tbflag 0线上 1线下
        if (val.tbflag == '1') {
          this.$router.push(`/productShowNhwx?prodcode=${val.prodcode}`)
          // &sclasscode=${val.sclasscode}
        } else {
          // 线上点击触发行为记录
          if (this.user.rytype == 'M') {
            window.location.href = val.comtburl
          } else {
            this.behaviorRecord(val)
          }
        }
      }
    },

    // 行为记录
    behaviorRecord (val) {
      let data = {
        suserid: this.suserid,
        empno: this.empno,
        sno: val.prodcode,
        otype: '1',
        btagcode: '7',
        rytype: this.rytype
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
        window.location.href = val.comtburl
        // alert(id)
      })
    },
    choseSupplyList () {
      if (this.user.comid == 'NHWX') {
        sxchoseList().then(res => {
          this.arr = res.data.data
          this.insuranceCompany = []
          let arrd = {
            value: '',
            text: '全部'
          }
          this.insuranceCompany.push(arrd)
          for (var i = 0; i < this.arr.length; i++) {
            let arrobject = {
              value: this.arr[i].baseid,
              text: this.arr[i].shortname
            }
            this.insuranceCompany.push(arrobject)
          }
        })
      } else {
        choseSupplyList().then(res => {
          this.arr = res.data.data
          this.insuranceCompany = []
          let arrd = {
            value: '',
            text: '全部'
          }
          this.insuranceCompany.push(arrd)
          for (var i = 0; i < this.arr.length; i++) {
            let arrobject = {
              value: this.arr[i].baseid,
              text: this.arr[i].shortname
            }
            this.insuranceCompany.push(arrobject)
          }
        })
      }
    },
    choseList () {
      choseList().then(res => {
        this.arrtype = res.data.data
        this.screenlist = []
        let ddc = {
          value: '',
          text: '全部'
        }
        this.screenlist.push(ddc)
        for (var i = 0; i < this.arrtype.length; i++) {
          let arrtypeobj = {
            value: this.arrtype[i].typecode,
            text: this.arrtype[i].typename
          }
          this.screenlist.push(arrtypeobj)
        }
      })
    },
    // screenclick(){
    //   this.showList()
    //   console.log("筛选",this.prodtype);
    // },
    changeCompany () {
      this.isSelectorShow = true
    },
    onSelectorChoose ({ value, text }) {
      this.form.shortname = value
      this.shortname = text
      this.baseid = value
      // console.log("选择公司", this.shortname, this.baseid);
      this.pageNo = 1
      this.getData()
    },
    changeType () {
      this.isChoseShow = true
    },
    onTypeChoose ({ value, text }) {
      this.form.typename = value
      this.typename = text
      this.prodtype = value
      this.pageNo = 1
      this.getData()
    },

    async getData (isInit = true) {
      if (window.location.pathname != '/nanhuaarrondi') {
        Toast.loading('加载中...')
      }
      let { pageNo, size } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let data = {
        page: this.pageNo,
        size,
        baseid: this.baseid,
        prodtype: this.prodtype,
        age: this.form.shortAgename,
        search: this.searchValue,
        type: '1'
      }
      let res = await showList(data).finally(() => {
        Toast.hide()
      })
      this.articlelist = isInit
        ? res.data.data.rows
        : this.articlelist.concat(res.data.data.rows)
      if (this.articlelist.length == 0) {
        this.isNull = true
      } else {
        this.isNull = false
      }
      this.total = res.data.data.total
      this.totalPage = res.data.data.totalpage
      return res
    },

    // 获取投保年龄
    getAge () {
      selectMJ({ list: ['TBNL'] }).then(res => {
        let arr = res.data.data[0].value
        // this.insuranceCompany = []
        this.insuranceAge = []
        let arrd = {
          value: '',
          text: '全部'
        }
        this.insuranceAge.push(arrd)
        for (var i = 0; i < arr.length; i++) {
          this.insuranceAge.push({
            value: arr[i].name,
            text: `${arr[i].name}岁`
          })
        }
      })
    },

    onSelectorChooseAge ({ value, text }) {
      this.form.shortAgename = value
      this.shortAgename = text
      // this.baseid = value
      // console.log("选择公司", this.shortname, this.baseid);
      this.pageNo = 1
      this.getData()
    },
    changeAge () {
      this.isSelectorAgeShow = true
    },
    showModal (item) {
      this.$router.push(`/information?prodcode=${item.prodcode}`)
    }
  }
}
</script>
<style scoped lang="stylus">
@import './index.styl';

.biaoqian {
  width: 70%;
  height: 0.5rem;
  background: #2598F3;
  position: absolute;
  color: white;
  font-size: 0.3rem;
  top: 0;
  right: 0;
  text-align: center;
  line-height: 0.5rem;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* 超出部分文字以...显示 */
  padding: 5px 12px;
  display: block;
}

.nodata {
  padding-top: 30%;
  text-align: center;
}
</style>
